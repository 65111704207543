<template>
  <div class="prescriptionConfig">
    <div class="specDetail">
      <el-button type="primary" @click="dataOperation(null, 2)">
        添加规范明细
      </el-button>
      <el-table :data="specDetailList" class="width-96" border>
        <el-table-column type="index" label="序号" width="50" />
        <el-table-column prop="specDetailContent" label="明细" align="center" />
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              :disabled="scope.$index === 0"
              @click="moveUp(scope)"
            >
              上移
            </el-button>
            <el-button
              type="text"
              size="small"
              :disabled="
                scope.$index === specDetailList.length - 1 ||
                specDetailList.length === 1
              "
              @click="moveDown(scope)"
            >
              下移
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="dataOperation(scope.row, 0)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="dataOperation(scope.row, 1)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="titleType + '处方规范明细'"
      :visible.sync="dialogVisible"
      width="35%"
    >
      <el-form
        v-if="dialogVisible"
        ref="form1"
        label-position="right"
        :model="form1"
        :rules="rules"
        label-width="160px"
        @submit.native.prevent
      >
        <h3 v-if="isDelete" style="margin-bottom: 20px; text-align: center">
          确认删除<span style="color: red">{{
            detailInfo.specDetailContent
          }}</span
          >信息吗？
        </h3>
        <template v-if="!isDelete">
          <el-form-item label="处方规范类型:">
            <el-select
              v-model="form1.specTypeCode"
              :disabled="titleType === '修改'"
            >
              <el-option
                v-for="item in specTypeList"
                :key="item.specTypeCode"
                :value="item.specTypeCode"
                :label="item.specTypeName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="处方规范明细编码:" prop="specDetailCode">
            <el-input
              v-model="form1.specDetailCode"
              v-check-permission
              placeholder="请输入处方规范明细编码"
              style="width: 80%"
              :disabled="titleType === '修改'"
              maxlength="10"
              @keyup.enter.native="saveSpecDetail()"
            />
          </el-form-item>
          <el-form-item label="处方规范明细内容:" prop="specDetailContent">
            <el-input
              v-model="form1.specDetailContent"
              placeholder="请输入处方规范明细内容"
              style="width: 80%; margin-right: 20px"
              type="textarea"
              :rows="5"
              maxlength="100"
              show-word-limit
              @keyup.enter.native="saveSpecDetail()"
            />
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveSpecDetail()">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import tinymc from '@/views/privacyPolicy/tinymcCom';
import { mapState } from 'vuex';
import { codeRule } from '@/utils/verificationRule';
export default {
  name: 'SpecDetail',
  components: {
    // tinymc,
  },
  props: {
    specDetailList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false, // 编辑新增删除弹框显示与否
      loading: false,
      titleType: '',
      isDelete: false, // 是否删除状态
      detailInfo: {}, // 编辑信息
      form1: {
        specDetailContent: '',
        specDetailCode: '',
        specTypeCode: '',
        prescriptionSpecCode: '',
      },
      rules: {
        specDetailContent: [
          { required: true, message: '请输入处方明细名称', trigger: 'blur' },
        ],
        specDetailCode: [
          { required: true, validator: codeRule, trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    ...mapState('prescriptionSpec', {
      specList: (state) => state.specList,
      specTypeList: (state) => state.specTypeList,
    }),
  },
  watch: {
    'form1.specTypeCode': function (value) {
      console.log('llllllllllll');
      this.specTypeList.forEach((item) => {
        console.log(item, 'item');
        if (value === item.specTypeCode) {
          this.form1.prescriptionSpecCode = item.prescriptionSpecCode;
          console.log(this.form1.prescriptionSpecCode, '1');
        }
      });
    },
  },
  methods: {
    // 规范移动
    move(item, nextItem, type) {
      this.specTypeList.forEach((item1) => {
        console.log(item, 'item');
        if (item.specTypeCode === item1.specTypeCode) {
          this.form1.prescriptionSpecCode = item1.prescriptionSpecCode;
        }
      });
      const num = {
        prescriptionSpecCode: this.form1.prescriptionSpecCode,
        specTypeCode: item.specTypeCode,
      };
      console.log(item, nextItem, type, 'zhobngf');
      const param = {
        id: item.id,
        updateId: nextItem.id,
        updateWeight: nextItem.weight,
        weight: item.weight,
      };
      this.$api.specDetailMoveUpOrDown(param).then((res) => {
        if (res.code === 0) {
          this.$message.success(type === 0 ? '上移成功' : '下移成功');
          this.$emit('refreshDetailList', num);
        }
      });
    },
    // 上移
    moveUp(item) {
      const { specDetailList } = this;
      const nextItem = specDetailList[item.$index - 1];
      this.move(item.row, nextItem, 0);
    },
    moveDown(item) {
      const { specDetailList } = this;
      console.log(item, '11', specDetailList);
      const nextItem = specDetailList[item.$index + 1];
      console.log(nextItem, 'pppppp');
      this.move(item.row, nextItem, 1);
    },
    // 新增处方类型规范
    dataOperation(item, type) {
      console.log(item, type);
      this.isDelete = false;
      switch (type) {
        case 0:
          this.titleType = '修改';
          this.detailInfo = item;
          this.editDetail(item);
          break;
        case 1:
          this.titleType = '删除';
          this.detailInfo = item;
          this.isDelete = true;
          break;
        case 2:
          this.titleType = '新增';
          this.addDetail();
          break;
        default:
          break;
      }
      this.dialogVisible = true;
    },
    // 编辑明细
    editDetail(data) {
      this.form1 = {
        specDetailContent: data.specDetailContent,
        specDetailCode: data.specDetailCode,
        specTypeCode: data.specTypeCode,
        prescriptionSpecCode: data.prescriptionSpecCode,
      };
    },
    // 新增
    addDetail() {
      this.form1 = {
        specDetailContent: '',
        specDetailCode: '',
        specTypeCode: '',
        prescriptionSpecCode: '',
      };
    },
    // 保存
    saveSpecDetail() {
      const { form1, titleType, detailInfo, isDelete } = this;
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          const param = {
            ...form1,
          };
          if (titleType !== '新增') {
            param.id = detailInfo.id;
          }
          isDelete ? this.del(param) : this.editOrAdd(param);
        } else {
          return false;
        }
      });
    },
    // 编辑或新增
    editOrAdd(param) {
      this.$api.editSpecDetail(param).then((res) => {
        if (res.code === 0) {
          this.$message.success(
            this.titleType === '修改' ? '修改处方明细成功' : '新增处方明细成功'
          );
          (this.dialogVisible = false),
            this.$emit('refreshDetailList', this.form1);
        }
      });
    },
    // 删除
    del(param) {
      this.$api.deleteSpecDetail(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('删除处方明细成功');
          (this.dialogVisible = false),
            this.$emit('refreshDetailList', this.form1);
        }
      });
    },
  },
};
</script>

<style scope>
.specDetail {
  margin: 50px 0px 0px 150px;
}
.width-96 {
  margin-top: 10px;
  width: 50%;
}
</style>
